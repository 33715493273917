<template>
  <div class="main">
    <p><img style="width:2rem; height:2rem; margin:.3rem 0" src="../../assets/images/icon-success.png" /></p>
    <p class="success">充值成功</p>
    <div class="btn-back" v-if="backShow" @click="goBack">返回</div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { APPJS } from '@/utils/native';
import { browser } from '@/utils'
import http from '@/http';
export default {
  setup() {
    const route = useRoute()
    let title = {}
    const backShow = ref(false)

    const goBack = () => {
      APPJS('closeweb');
    }

    onMounted(() => {
      let ua = navigator.userAgent
      if(ua.indexOf('Mobile xingqiuxiuchang') > -1){
        backShow.value = true
      }

      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.PfEnumService.getEnum', { enumName: 'TransportEnum' }).then(res => {
        title = res.result.find(val => val.code === route.query.Source)
        document.title = title.value + '充值'
      })
    })

    return {
      goBack,
      backShow
    }
  },
}
</script>
<style lang="less" scoped>
.main {
  text-align: center;
  padding: 1.8rem 1rem;
  .success {
    font-size: 0.32rem;
    font-weight: bold;
    margin-top: 0.4rem;
  }
  .btn-back {
    width: 6.9rem;
    height: 0.88rem;
    border-radius: 0.1rem;
    background: #2dc7ff;
    color: #fff;
    font-size: 0.32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem auto;
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
  }
}
</style>